import { useEffect, useState } from "react";
import Button from "./Button";
import InputBox from "./InputBox";
import Modal from "./Modal";
import { useRouteHelper } from "../utils/RouteHelper";

const RegisterModal = () => {
  const { goLogin, goLoginSuccess, goInvalidAccessCode, goDuplicateUserError } = useRouteHelper();
  const [orgInput, setOrgInput] = useState("");
  const [roleInput, setRoleInput] = useState("");
  const [firstNameInput, setFirstNameInput] = useState("");
  const [lastNameInput, setLastNameInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const [confirmPasswordInput, setConfirmPasswordInput] = useState("");
  const [accessCodeInput, setAccessCodeInput] = useState("");
  const [isMatchingPassword, setIsMatchingPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [canRegister, setCanRegister] = useState(false);
  const [readonly, setReadonly] = useState(false);

  const handleConfirmPasswordChange = (newValue: string) => {
    setConfirmPasswordInput(newValue);
    setIsMatchingPassword(passwordInput === newValue);
  };
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const inviteCode = urlParams.get("inviteCode");
    if (inviteCode) setAccessCodeInput(inviteCode);
  }, []);

  useEffect(() => {
    if (!accessCodeInput) return;

    fetch(`${process.env.REACT_APP_API_URL}/user/code-lookup`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        inviteCode: accessCodeInput,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else if (response.status === 400) {
          return response.json().then((errorResponse) => {
            setCanRegister(false);
            goInvalidAccessCode();
            setErrorMessage(errorResponse.error);
            throw new Error("Invalid access code. " + response.status);
          });
        } else if (response.status === 409){
          return response.json().then((errorResponse) => {
            setCanRegister(false);
            goDuplicateUserError();
            setErrorMessage(errorResponse.error);
            throw new Error("Invalid access code. " + response.status);
          });
        } else {
          setErrorMessage(
            "An unexpected error occurred. Please contact your admin. Error Code: " +
              response.status,
          );
          throw new Error("An unexpected error occurred. " + response.status);
        }
      })
      .then((responseJSON) => {
        setCanRegister(true);
        setReadonly(true);
        setOrgInput(responseJSON.data.organizationName);
        setRoleInput(responseJSON.data.roleName);
        console.log("Valid Invite Code! Successful lookup:", responseJSON);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [accessCodeInput]);

  const proceedRegister = () => {
    if (
      !firstNameInput ||
      !lastNameInput ||
      !accessCodeInput ||
      !passwordInput
    ) {
      alert("Please fill in all fields.");
      return;
    }
    if (!isMatchingPassword) {
      alert("Passwords do not match.");
      return;
    }
    if (passwordInput.length < 8) {
      alert("Password must be at least 8 characters long.");
      return;
    }

    fetch(`${process.env.REACT_APP_API_URL}/users/sign-up`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        firstName: firstNameInput,
        lastName: lastNameInput,
        password: passwordInput,
        userCode: accessCodeInput,
      }),
    })
      .then((response) => {
        if (response.status === 201) {
          return response.json();
        } else if (response.status === 400) {
          return response.json().then((errorResponse) => {
            setErrorMessage(errorResponse.error);
            throw new Error(errorResponse.message);
          });
        } else if (response.status === 409) {
          return response.json().then((errorResponse) => {
            setErrorMessage(errorResponse.error);
            throw new Error(errorResponse.message);
          });
        } else {
          throw new Error("An unexpected error occurred.");
        }
      })
      .then((responseJSON) => {
        console.log("Signup successful:", responseJSON);
        goLoginSuccess();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return canRegister ? (
    <Modal title="Create New Account">
      <p className="text-xs text-left">
        Your email has been successfully verified. Please fill out all of your
        account information.
      </p>
      <InputBox
        label="Organization"
        labelSize={16}
        value={orgInput}
        onChange={setOrgInput}
        placeholder="Organization Name"
        readonly={readonly}
      />
      <InputBox
        label="Role"
        labelSize={16}
        value={roleInput}
        onChange={setRoleInput}
        placeholder="Your Role"
        readonly={readonly}
      />
      <InputBox
        label="First Name"
        labelSize={16}
        value={firstNameInput}
        onChange={setFirstNameInput}
        placeholder="First Name"
      />
      <InputBox
        label="Last Name"
        labelSize={16}
        value={lastNameInput}
        onChange={setLastNameInput}
        placeholder="Last Name"
      />
      <InputBox
        label="Password"
        labelSize={16}
        value={passwordInput}
        onChange={setPasswordInput}
        placeholder="Password"
      />
      <InputBox
        label="Confirm Password"
        labelSize={16}
        value={confirmPasswordInput}
        onChange={handleConfirmPasswordChange}
        placeholder="Retype Password"
        isMatchingPassword={isMatchingPassword}
      />
      {errorMessage && (
        <div className="w-full h-fit text-left text-red-700 bg-red-200 px-4 py-2.5 mb-2 rounded-md leading-5 ring-1 ring-red-300">
          <p>{errorMessage}</p>
        </div>
      )}
      <Button
        text="CONTINUE"
        backgroundColor="bg-empathED-blue"
        onClick={proceedRegister}
      ></Button>
      <button
        className="text-empathED-blue text-xs font-bold ml-1"
        onClick={goLogin}
      >
        Back to log in
      </button>
    </Modal>
  ) : (
    <div></div>
  );
};

export default RegisterModal;
