import React from "react";
import { Question } from "../../utils/globals";
import WrongIcon from "@mui/icons-material/Close";
import RightIcon from "@mui/icons-material/Check";

interface QuestionResultModuleProps {
  question: Question;
  questionIndex: number;
  answers: string[];
}

const QuestionResultModule: React.FC<QuestionResultModuleProps> = ({
  question,
  questionIndex,
  answers,
}) => {
  const isCorrect = () => {
    if (answers.length === 0 && question.CorrectAnswer.length === 0)
      return false;
    if (answers.length === 0 && question.CorrectAnswer.length > 0) return false;
    return (
      (answers.filter((ans) => question.CorrectAnswer.includes(ans)).length ===
        question.CorrectAnswer.length &&
        !answers.some((ans) => !question.CorrectAnswer.includes(ans))) ||
      question.CorrectAnswer.length === 0
    );
  };
  const isSelected = (index: number) => {
    return answers.includes(question.AnswerChoices[index]);
  };
  const isCorrectOption = (index: number) => {
    return question.CorrectAnswer.includes(question.AnswerChoices[index]);
  };

  return (
    <div className="flex flex-col justify-center items-center gap-6 w-full mb-5">
      <div className="flex flex-row w-full h-fit gap-2">
        <div>
          {isCorrect() ? (
            <RightIcon
              className="text-medium-green"
              sx={{ marginTop: "-8px" }}
            />
          ) : (
            <WrongIcon className="text-medium-red" sx={{ marginTop: "-8px" }} />
          )}
        </div>
        <div
          className={`text-left flex-grow font-semibold m-auto ${
            isCorrect() ? "text-medium-green" : "text-medium-red"
          }`}
        >
          <span>
            {questionIndex + 1}. {question.Question}
          </span>
          {!question.Optional && <span className="text-medium-red"> *</span>}
        </div>
        <div className="text-right flex-none self-start font-light">
          {isCorrect() ? question.Points : 0}
          {"/"}
          {question.Points}
        </div>
      </div>
      <div className="flex flex-col items-center gap-1 w-full">
        {question.AnswerChoices.map((option, answerIndex) => {
          return (
            <div
              key={answerIndex}
              className={`
              ${
                isSelected(answerIndex) &&
                !isCorrectOption(answerIndex) &&
                question.CorrectAnswer.length !== 0
                  ? "bg-light-red"
                  : ""
              }
              ${isCorrectOption(answerIndex) ? "bg-light-green" : ""}
              ${
                isSelected(answerIndex) && question.CorrectAnswer.length === 0
                  ? "bg-light-green"
                  : ""
              }
               p-1.5 pl-8 pr-3 flex flex-row items-center gap-2 w-full rounded-md`}
            >
              {question.CorrectAnswer.length > 1 ? (
                // multiple selection
                <input
                  readOnly
                  type="checkbox"
                  name={question.Question}
                  value={option}
                  className={`w-4 h-4 ${
                    isCorrectOption(answerIndex) ||
                    question.CorrectAnswer.length === 0
                      ? "accent-medium-green"
                      : ""
                  } ${
                    !isCorrectOption(answerIndex) &&
                    question.CorrectAnswer.length !== 0
                      ? "accent-medium-red"
                      : ""
                  }`}
                  checked={isSelected(answerIndex)}
                />
              ) : (
                // single selection
                <input
                  readOnly
                  type="radio"
                  name={question.Question}
                  value={option}
                  className={`w-4 h-4 ${
                    isCorrectOption(answerIndex) ||
                    question.CorrectAnswer.length === 0
                      ? "accent-medium-green"
                      : ""
                  } ${
                    !isCorrectOption(answerIndex) &&
                    question.CorrectAnswer.length !== 0
                      ? "accent-medium-red"
                      : ""
                  }`}
                  checked={isSelected(answerIndex)}
                />
              )}
              <label
                className={`text-sm text-left w-full font-medium ${
                  isSelected(answerIndex) ? "text-gray-800" : "text-gray-500"
                }`}
              >
                {option}
              </label>
              {isSelected(answerIndex) &&
                (isCorrectOption(answerIndex) ||
                question.CorrectAnswer.length === 0 ? (
                  <RightIcon
                    className="text-medium-green"
                    sx={{ margin: "auto" }}
                  />
                ) : (
                  <WrongIcon
                    className="text-medium-red"
                    sx={{ margin: "auto" }}
                  />
                ))}
            </div>
          );
        })}
      </div>
      <div className="w-full h-fit bg-grey text-left px-8 py-4 mb-4 rounded-md">
        <h4 className="text-xs mb-2">FEEDBACK</h4>
        <p className="font-medium">
          {isCorrect() ? question.correctFeedback : question.incorrectFeeback}
        </p>
      </div>
    </div>
  );
};

export default QuestionResultModule;
