import { Quiz } from "../../utils/globals";
import adhdQuiz from "../../pages/quiz_pages/quiz_content/ADHDQuiz.json";
import QuizPage from "./QuizPage";

const ADHDQuizModule = () => {
  const quiz: Quiz = adhdQuiz as Quiz;
  return <QuizPage quiz={quiz} />;
};

export default ADHDQuizModule;
