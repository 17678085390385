import anxietyQuiz from "../../pages/quiz_pages/quiz_content/AnxietyQuiz.json";
import { Quiz } from "../../utils/globals";
import QuizPage from "./QuizPage";

const AnxietyQuizModule = () => {
  const quiz: Quiz = anxietyQuiz as Quiz;
  return <QuizPage quiz={quiz} />;
};

export default AnxietyQuizModule;
