import { useState } from "react";
import InputBox from "./InputBox";
import Button from "./Button";
import { useRouteHelper } from "../utils/RouteHelper";
import Modal from "./Modal";

const LoginEmailPasswordModal = () => {
  const { goAccessCode, goExperience, goResetPassword } = useRouteHelper();
  const [emailInput, setEmailInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const proceedLogin = () => {
    if (!emailInput || !passwordInput) {
      alert("Please enter both email and password.");
      return;
    }
    fetch(`${process.env.REACT_APP_API_URL}/users/login`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include", // To include cookies such as the session ID
      body: JSON.stringify({
        email: emailInput,
        password: passwordInput,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else if (response.status === 401) {
          return response.json().then((errorResponse) => {
            throw new Error(errorResponse.error);
          });
        } else {
          throw new Error("An unexpected error occurred when authentication.");
        }
      })
      .then((responseJSON) => {
        console.log("Login successful:", responseJSON);
        goExperience();
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  return (
    <Modal>
      <InputBox
        label="Email"
        labelSize={16}
        value={emailInput}
        onChange={setEmailInput}
        placeholder="Email Address"
      />
      <InputBox
        label="Password"
        labelSize={16}
        value={passwordInput}
        onChange={setPasswordInput}
        placeholder="Password"
        caption="Forgot Password?"
        captionPosition="right"
        onClick={goResetPassword}
        isLoginPassword={true}
      />
      <Button
        text="LOG IN"
        backgroundColor="bg-empathED-blue"
        onClick={proceedLogin}
      ></Button>
      {/* <div className="text-xs flex flex-row">
        <p className="font-bold text-gray-500">Don’t have an account? </p>
        <button
          className="text-empathED-blue font-bold ml-1"
          onClick={goAccessCode}
        >
          Register
        </button>
      </div> */}
    </Modal>
  );
};

export default LoginEmailPasswordModal;
