export const useFetchHelper = () => {
  // check if user is login / valid, return true if valid.
  const verifySession = async (): Promise<Boolean> => {
    return fetch(`${process.env.REACT_APP_API_URL}/users/check-session`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then((response) => {
        if (response.status === 200) {
          console.log("Valid Session, Login successful: ", response.status);
          return true;
        } else if (response.status === 404) {
          console.log("Session Not Found or Expired. " + response.status);
          return false;
        } else {
          throw new Error("An unexpected error occurred. " + response.status);
        }
      })
      .catch((error) => {
        alert(error.message);
        return false;
      });
  };
  return { verifySession };
};
