import React from "react";
interface QuizModalProps {
  children?: React.ReactNode;
  title: string | null;
  layout: string;
}
const QuizModal: React.FC<QuizModalProps> = ({ title, children, layout }) => {
  return (
    <div className={`bg-empathED-light-blue w-screen h-full`}>
      <div className="flex justify-center items-center w-full h-fit overflow-auto">
        <div className="relative w-full max-w-3xl p-20 bg-white rounded-xl my-20">
          <div className="relative flex flex-col items-center w-full">
            <div
              className={`flex flex-col justify-center ${layout} gap-4 w-full`}
            >
              <div className="w-full py-3">
                <img
                  className="w-50 left-0"
                  src="/EmpathED_text_logo.png"
                  alt="EmpathED Logo"
                />
              </div>
              {title && (
                <h1 className="font-bold text-xl text-black text-left w-full -mb-2.5">
                  {title}
                </h1>
              )}
              {title && (
                <div className="self-start w-20 h-0.5 bg-empathED-medium-blue mb-3.5"></div>
              )}
              <div className="flex-1">
                <div className="pt-4">{children}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuizModal;
