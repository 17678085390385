import "./App.css";
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginMainPage from "./pages/LoginMainPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import RegisterPage from "./pages/RegisterPage";
import ResetPasswordEmailPage from "./pages/ResetPasswordEmailPage";
import NewPasswordPage from "./pages/NewPasswordPage";
import AccessCode from "./pages/AccessCodePage";
import VerifyAccessEmailPage from "./pages/VerifyAccessEmailPage";
import InvalidAccessCodePage from "./pages/InvalidAccessCodePage";
import SignupSuccessPage from "./pages/SignupSuccessPage";
import ExperiencePage from "./pages/ExperiencePage";
import MainPage from "./pages/MainPage";
import SetNewPasswordSuccessPage from "./pages/SetNewPasswordSuccessPage";

import AnxietyQuizModule from "./pages/quiz_pages/AnxietyQuizModule";
import QuizFailPage from "./pages/quiz_pages/QuizFailPage";
import QuizPassPage from "./pages/quiz_pages/QuizPassPage";
import ADHDQuizModule from "./pages/quiz_pages/ADHDQuizModule";
import DepressionQuizModule from "./pages/quiz_pages/DepressionQuizModule";
import DyslexiaQuizModule from "./pages/quiz_pages/DyslexiaQuizModule";
import SendCertificatePage from "./pages/quiz_pages/SendCertificatePage";
import TestResultsPage from "./pages/quiz_pages/TestResultsPage";
import DuplicateUserErrorPage from "./pages/DuplicateUserErrorPage";

const App: React.FC = () => {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/login" element={<LoginMainPage />} />
          <Route path="/reset-password" element={<ResetPasswordPage />} />
          <Route
            path="/reset-password-email"
            element={<ResetPasswordEmailPage />}
          />
          <Route path="/set-new-password" element={<NewPasswordPage />} />
          <Route
            path="/set-new-password-success"
            element={<SetNewPasswordSuccessPage />}
          />
          // TODO Enable this when we enable manual access code
          {/* <Route path="/access-code" element={<AccessCode />} />
          <Route
            path="/verify-access-email"
            element={<VerifyAccessEmailPage />}
          /> */}
          <Route
            path="/invalid-access-code"
            element={<InvalidAccessCodePage />}
          />
          <Route path="/existing-user" element={<DuplicateUserErrorPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/login-success" element={<SignupSuccessPage />} />
          <Route path="/experience" element={<ExperiencePage />} />
          {/* quiz section */}
          <Route
            path="/experience/anxiety-quiz"
            element={<AnxietyQuizModule />}
          />
          <Route path="/experience/adhd-quiz" element={<ADHDQuizModule />} />
          <Route
            path="/experience/depression-quiz"
            element={<DepressionQuizModule />}
          />
          <Route
            path="/experience/dyslexia-quiz"
            element={<DyslexiaQuizModule />}
          />
          <Route path="/experience/fail-quiz" element={<QuizFailPage />} />
          <Route path="/experience/pass-quiz" element={<QuizPassPage />} />
          <Route
            path="/experience/send-certificate"
            element={<SendCertificatePage />}
          />
          <Route path="/experience/test-result" element={<TestResultsPage />} />
          <Route path="/send-certificate" element={<SendCertificatePage />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
