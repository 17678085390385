import React, { useEffect, useState } from "react";
import { useFetchHelper } from "../../utils/FetchHelper";
import { useRouteHelper } from "../../utils/RouteHelper";
import Page from "../Page";
import BasicQuizPage from "./BasicQuizPage";
import { Quiz } from "../../utils/globals";

interface QuizPageProps {
  quiz: Quiz;
}
const QuizPage: React.FC<QuizPageProps> = ({ quiz }) => {
  const { goLogin } = useRouteHelper();
  const { verifySession } = useFetchHelper();
  const [canAccess, setCanAccess] = useState<boolean | null>(null);
  useEffect(() => {
    verifySession().then((isValid) => {
      if (!isValid) {
        goLogin();
        setCanAccess(false);
      } else {
        setCanAccess(true);
      }
    });
  }, []);
  return canAccess ? (
    <BasicQuizPage quiz={quiz} />
  ) : (
    <Page bgImage="bg-login-bg">
      <div className="w-screen h-screen text-5xl text-center text-white mt-40">
        Loading...
      </div>
    </Page>
  );
};

export default QuizPage;
