import React from "react";
import Button from "../../components/Button";
import QuizModal from "../../components/QuizModal";
import { useRouteHelper } from "../../utils/RouteHelper";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { setUserEmail } from "./quiz-slice";

const QuizPassPage = () => {
  const { goTestResult, goSendCertificate } = useRouteHelper();
  const urlParams = new URLSearchParams(window.location.search);
  const dispatch = useDispatch();

  const title = urlParams.get("title") ? urlParams.get("title") : "Quiz";
  const quizAttemptId = useSelector(
    (state: RootState) => state.quizSliceReducer.attempts,
  );
  const sendCertificate = () => {
    // TODO: send certificate call backend api
    fetch(
      `${process.env.REACT_APP_API_URL}/issue-certificate/${quizAttemptId}`,
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include", // To include cookies such as the session ID
      },
    )
      .then((response) => {
        if (response.status === 200) {
          console.log("Certificate sent successfully");
          return response.json();
        } else {
          throw new Error("Certificate Failed");
        }
      })
      .then((responseJSON) => {
        const email = responseJSON.userEmail;
        dispatch(setUserEmail(email));
      }).then(() => {
        goSendCertificate(title ? title : "Quiz");
      })
      .catch((error) => {
        alert(error.message);
      });
  };
  const viewResults = () => {
    goTestResult(title ? title : "Quiz");
  };

  const quizScore = useSelector(
    (state: RootState) => state.quizSliceReducer.scorePercentage,
  );
  const correctAnswers = useSelector(
    (state: RootState) => state.quizSliceReducer.correctQuestions,
  );
  const totalQuestions = useSelector(
    (state: RootState) => state.quizSliceReducer.totalQuestions,
  );
  return (
    <div
      className={`bg-scroll bg-empathED-light-blue bg-cover bg-center fixed w-screen h-screen my-100`}
    >
      <div className="flex justify-center items-center w-full h-full overflow-y-auto">
        <QuizModal title={title} layout="items-left">
          <div className="flex flex-col gap-4 mb-10">
            <div className="text-left">Thank you for your submission. </div>
            <div className="text-left">
              You answered{" "}
              <span className="text-empathED-blue font-bold text-medium">
                {correctAnswers}/{totalQuestions}
              </span>{" "}
              questions correctly, and your final score is{" "}
              <span className="text-empathED-blue font-bold text-medium">
                {(quizScore * 100).toFixed(2)}%
              </span>
              . Congratulations, you passed this course!
            </div>
            <div className="text-left">
              Click below to receive a certificate of course completion via
              email.
            </div>
            <div className="text-left">
              You may also choose to view your quiz results below.
            </div>
          </div>
          <div className="flex flex-grow flex-row gap-4 my-5">
            <Button
              text="SEND CERTIFICATE"
              backgroundColor="bg-empathED-blue"
              onClick={sendCertificate}
            ></Button>
            <Button
              text="VIEW RESULTS"
              backgroundColor="bg-white"
              textColor="text-empathED-blue"
              borderColor="border-empathED-blue"
              onClick={viewResults}
            ></Button>
          </div>
        </QuizModal>
      </div>
    </div>
  );
};

export default QuizPassPage;
