import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Quiz } from "../../utils/globals";
import Button from "../../components/Button";
import { useRouteHelper } from "../../utils/RouteHelper";
import QuizModal from "../../components/QuizModal";
import {
  addAnswer,
  clearAnswers,
  setAttempts,
  setCorrectQuestions,
  setQuiz,
  setScorePercentage,
  setTotalQuestions,
} from "./quiz-slice";
import { RootState } from "../../store";

interface BasicQuizPageProps {
  quiz: Quiz;
}
const BasicQuizPage: React.FC<BasicQuizPageProps> = ({ quiz }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setQuiz(quiz));
  }, []);

  const { goFailQuiz, goPassQuiz } = useRouteHelper();
  const quizAnswer = useSelector(
    (state: RootState) => state.quizSliceReducer.answers,
  );
  const submitQuizAttempt = (score: number, hasPassed: boolean) => {
    fetch(`${process.env.REACT_APP_API_URL}/quiz-attempt`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include", // To include cookies such as the session ID
      body: JSON.stringify({
        courseId: quiz.ID,
        quizScore: score,
        passed: hasPassed,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          console.log("Quiz attempt submitted successfully");
          return response.json();
        } else {
          throw new Error("Quiz attempt Failed");
        }
      })
      .then((responseJSON) => {
        const attemptId = responseJSON.quizAttemptId;
        dispatch(setAttempts(attemptId));
      })
      .catch((error) => {
        alert(error.message);
      });
  };
  const calculateQuizScore = (quiz: Quiz) => {
    let correctPoints = 0;
    let totalPoints = 0;

    quiz.Questions.forEach((question, questionIndex) => {
      const userAnswer = quizAnswer[questionIndex] || [];
      const correctAnswer = new Set(question.CorrectAnswer);
      const questionPoints = question.Points;

      if (correctAnswer.size === 0 && userAnswer.length != 0) {
        // Any answer is correct if no correct answer is defined
        correctPoints += questionPoints;
      } else if (userAnswer.length == 0) {
        correctPoints += 0;
      } else {
        // If there is incorrect answer, then no point.
        const hasIncorrect = userAnswer.some((ans) => !correctAnswer.has(ans));
        correctPoints +=
          !hasIncorrect && userAnswer.length === correctAnswer.size
            ? questionPoints
            : 0;
      }

      totalPoints += questionPoints;
    });

    const finalScore = correctPoints / totalPoints;
    dispatch(setScorePercentage(finalScore));
    dispatch(setCorrectQuestions(correctPoints));
    dispatch(setTotalQuestions(totalPoints));
    return { finalScore };
  };
  const submitAnswer = () => {
    //Check if all the required field is filled
    for (
      let questionIndex = 0;
      questionIndex < quiz.Questions.length;
      questionIndex++
    ) {
      const question = quiz.Questions[questionIndex];
      const userAnswer = quizAnswer[questionIndex];
      if (!question.Optional && userAnswer.length === 0) {
        //Can't submit without all required field
        alert("Please answer all required questions before submitting.");
        return;
      }
    }

    const { finalScore } = calculateQuizScore(quiz);
    submitQuizAttempt(finalScore, finalScore >= 0.8);

    if (finalScore >= 0.8) {
      goPassQuiz(quiz.Title);
    } else {
      goFailQuiz(quiz.Title);
    }
  };

  return (
    <QuizModal title={quiz.Title} layout="items-center">
      {quiz &&
        quiz.Questions.map((question, questionIndex) => {
          return (
            <div
              key={questionIndex}
              className="flex flex-col justify-center items-center gap-6 w-full mb-8"
            >
              <div className="flex flex-row w-full gap-4">
                <div className="text-black text-left w-full flex-grow font-medium">
                  <span>
                    {questionIndex + 1}. {question.Question}
                  </span>
                  {!question.Optional && (
                    <span className="text-red-500"> *</span>
                  )}
                </div>
                <span className="text-sm text-black text-right flex-none">
                  {question.Points} {question.Points > 1 ? "points" : "point"}
                </span>
              </div>
              <div className="flex flex-col items-center gap-3 w-full">
                {question.AnswerChoices.map((option, answerIndex) => {
                  return (
                    <div
                      key={answerIndex}
                      className="flex flex-row items-center gap-2 w-full ml-8"
                    >
                      {question.CorrectAnswer.length > 1 ? (
                        // multiple selection
                        <input
                          type="checkbox"
                          name={question.Question}
                          value={option}
                          className="w-4 h-4"
                          onChange={(e) => {
                            // Retrieve current answers for the question
                            const currentAnswers =
                              quizAnswer[questionIndex] || [];

                            // Add or remove the option based on checkbox state
                            const updatedAnswers = e.target.checked
                              ? [...currentAnswers, option] // Add option
                              : currentAnswers.filter((ans) => ans !== option); // Remove option

                            // Dispatch with corrected payload
                            dispatch(
                              addAnswer({
                                questionIndex, // Matches reducer
                                answers: updatedAnswers, // Send updated array
                              }),
                            );
                          }}
                        />
                      ) : (
                        // single selection
                        <input
                          type="radio"
                          name={question.Question}
                          value={option}
                          className="w-4 h-4"
                          onChange={(e) => {
                            console.log(
                              "Checkbox Changed:",
                              e.target.value,
                              e.target.checked,
                            );
                            dispatch(
                              addAnswer({
                                questionIndex, // Matches reducer
                                answers: [option], // Send updated array
                              }),
                            );
                          }}
                        />
                      )}
                      <label className="text-sm text-left w-full font-medium">
                        {option}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      <div className="w-full mt-6">
        <div className="relative">
          <Button text="SUBMIT" onClick={submitAnswer}></Button>
        </div>
      </div>
    </QuizModal>
  );
};

export default BasicQuizPage;
