import { useNavigate } from "react-router-dom";

export const useRouteHelper = () => {
  const navigate = useNavigate();
  const goLogin = () => {
    navigate("/login");
  };
  const goExperience = () => {
    navigate("/experience");
  };
  const goRegister = () => {
    navigate("/register");
  };
  const goResetPassword = () => {
    navigate("/reset-password");
  };
  const goResetPasswordEmail = (email: string) => {
    navigate("/reset-password-email?userEmail=" + email);
  };
  const goSetNewPasswordSuccess = () => {
    navigate("/set-new-password-success");
  };
  const goLoginSuccess = () => {
    navigate("/login-success");
  };
  const goVerifyAccessEmail = (email: string) => {
    navigate("/verify-access-email?userEmail=" + email);
  };
  const goAccessCode = () => {
    navigate("/access-code");
  };
  const goInvalidAccessCode = () => {
    navigate("/invalid-access-code");
  };

  const goFailQuiz = (title: string) => {
    navigate("/experience/fail-quiz?title=" + title);
  };
  const goPassQuiz = (title: string) => {
    navigate("/experience/pass-quiz?title=" + title);
  };
  const goTestResult = (title: string) => {
    navigate("/experience/test-result?title=" + title);
  };
  const goADHDQuiz = () => {
    navigate("/experience/adhd-quiz");
  };
  const goAnxietyQuiz = () => {
    navigate("/experience/anxiety-quiz");
  };
  const goDyslexiaQuiz = () => {
    navigate("/experience/dyslexia-quiz");
  };
  const goDepressionQuiz = () => {
    navigate("/experience/depression-quiz");
  };
  const goSendCertificate = (title: string) => {
    navigate("/experience/send-certificate?title=" + title);
  };
  const goDuplicateUserError = () => {
    navigate("/existing-user");
  };
  return {
    goLogin,
    goExperience,
    goRegister,
    goResetPassword,
    goResetPasswordEmail,
    goSetNewPasswordSuccess,
    goLoginSuccess,
    goVerifyAccessEmail,
    goAccessCode,
    goInvalidAccessCode,
    goPassQuiz,
    goFailQuiz,
    goTestResult,
    goADHDQuiz,
    goAnxietyQuiz,
    goDyslexiaQuiz,
    goDepressionQuiz,
    goSendCertificate,
    goDuplicateUserError,
  };
};
