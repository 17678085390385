import React from "react";
import BasicQuizPage from "./BasicQuizPage";
import QuizModal from "../../components/QuizModal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { Quiz } from "../../utils/globals";
import QuestionResultModule from "./QuestionResultModule";
import Button from "../../components/Button";
import { useRouteHelper } from "../../utils/RouteHelper";
import { setUserEmail } from "./quiz-slice";

const TestResults = () => {
  const { goExperience, goSendCertificate } = useRouteHelper();
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const title = urlParams.get("title") ? urlParams.get("title") : "Quiz";
  const { correctQuestions, totalQuestions, quiz, answers, attempts } =
    useSelector((state: RootState) => state.quizSliceReducer);
  const sendCertificate = () => {
    // TODO: send certificate call backend api
    fetch(`${process.env.REACT_APP_API_URL}/issue-certificate/${attempts}`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include", // To include cookies such as the session ID
    })
      .then((response) => {
        if (response.status === 200) {
          console.log("Certificate sent successfully");
          return response.json();
        } else {
          throw new Error("Certificate Failed");
        }
      })
      .then((responseJSON) => {
        const email = responseJSON.userEmail;
        dispatch(setUserEmail(email));
        goSendCertificate(title ? title : "Quiz");
      })
      .catch((error) => {
        alert(error.message);
      });
  };
  return (
    <QuizModal title={title} layout="items-center">
      <div className="flex flex-row w-full gap-4 align-middle mb-10 h-fit">
        <div className="my-auto font-bold text-empathED-blue">Total Points</div>
        <div className="bg-empathED-blue rounded-md px-4 py-2 text-white font-bold">
          {correctQuestions} / {totalQuestions}
        </div>
      </div>
      {quiz.Questions.map((question: any, index: number) => {
        return (
          <QuestionResultModule
            question={question}
            questionIndex={index}
            answers={answers[index]}
          />
        );
      })}
      <div className="flex flex-grow flex-row gap-4 mt-5">
        <Button
          text="SEND CERTIFICATE"
          backgroundColor="bg-empathED-blue"
          onClick={sendCertificate}
        ></Button>
        <Button
          text="BACK TO HOME"
          backgroundColor="bg-empathED-blue"
          onClick={goExperience}
        ></Button>
      </div>
    </QuizModal>
  );
};

export default TestResults;
