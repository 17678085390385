import { Quiz } from "../../utils/globals";
import depressionQuiz from "../../pages/quiz_pages/quiz_content/DepressionQuiz.json";
import QuizPage from "./QuizPage";

const DepressionQuizModule = () => {
  const quiz: Quiz = depressionQuiz as Quiz;
  return <QuizPage quiz={quiz} />;
};

export default DepressionQuizModule;
