import Page from "./Page";
import DuplicateUserErrorModal from "../components/DuplicateUserErrorModal";

const DuplicateUserErrorPage = () => {
  return (
    <Page bgImage="bg-login-bg">
      <DuplicateUserErrorModal />
    </Page>
  );
};

export default DuplicateUserErrorPage;