import { useSelector } from "react-redux";
import QuizModal from "../../components/QuizModal";
import { RootState } from "../../store";
import Button from "../../components/Button";
import { useRouteHelper } from "../../utils/RouteHelper";

const SendCertificatePage = () => {
  const { goExperience } = useRouteHelper();
  const urlParams = new URLSearchParams(window.location.search);
  const title = urlParams.get("title") ? urlParams.get("title") : "Quiz";
  const userEmail = useSelector(
    (state: RootState) => state.quizSliceReducer.userEmail,
  );
  return (
    <div
      className={`bg-scroll bg-empathED-light-blue bg-cover bg-center fixed w-screen h-screen my-100`}
    >
      <div className="flex justify-center items-center w-full h-full overflow-y-auto">
        <QuizModal title={title} layout="items-left">
          <div className="text-left mb-5">
            Your certificate of course completion for the {title} will be
            emailed to
            <span className="text-empathED-blue font-bold ml-1">
              {userEmail}
            </span>
            .
          </div>
          <div className="text-left mb-5">
            It should arrive in your inbox within the next few hours.
          </div>
          <div className="text-left mb-8">
            If you have any questions about your certificate, please contact
            <span className="text-empathED-blue font-bold ml-1">
              info@empatheducate.com
            </span>
            .
          </div>
          <div className="flex flex-grow flex-row gap-4 mt-5">
            <Button
              text="BACK TO HOME"
              backgroundColor="bg-empathED-blue"
              onClick={goExperience}
            ></Button>
          </div>
        </QuizModal>
      </div>
    </div>
  );
};

export default SendCertificatePage;
