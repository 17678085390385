import { useEffect, useState } from "react";
import Modal from "./Modal";
import InputBox from "./InputBox";
import Button from "./Button";
import { useRouteHelper } from "../utils/RouteHelper";

export default function NewPasswordModal() {
  const { goLogin, goSetNewPasswordSuccess } = useRouteHelper();
  const [newPasswordInput, setNewPasswordInput] = useState("");
  const [confirmPasswordInput, setConfirmPasswordInput] = useState("");
  const [token, setToken] = useState("");
  const [encodedEmail, setEncodedEmail] = useState("");
  const [isMatchingPassword, setIsMatchingPassword] = useState(false);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenInput = urlParams.get("token");
    const emailTokenInput = urlParams.get("emailToken");
    if (tokenInput) setToken(tokenInput);
    if (emailTokenInput) setEncodedEmail(emailTokenInput);
  }, []);

  const handleConfirmPasswordChange = (newValue: string) => {
    setConfirmPasswordInput(newValue);
    setIsMatchingPassword(newPasswordInput === newValue);
  };

  const setPassword = () => {
    if (!newPasswordInput || !confirmPasswordInput) {
      alert("Please enter a new password and confirm it.");
      return;
    }
    if (!isMatchingPassword) {
      alert("Passwords do not match.");
      return;
    }
    if (newPasswordInput.length < 8) {
      alert("Password must be at least 8 characters long.");
      return;
    }
    fetch(
      `${process.env.REACT_APP_API_URL}/reset-password/${token}/${encodedEmail}`,
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include", // To include cookies such as the session ID
        body: JSON.stringify({
          newPassword: newPasswordInput,
        }),
      },
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else if (response.status === 400) {
          return response.json().then((errorResponse) => {
            throw new Error(errorResponse.error);
          });
        } else {
          throw new Error("An unexpected error occurred.");
        }
      })
      .then((responseJSON) => {
        console.log("Successfully set new password:", responseJSON);
        goSetNewPasswordSuccess();
      })
      .catch((error) => {
        alert(error.message);
      });
  };
  return (
    <Modal title="Set New Password">
      <InputBox
        label="New Password"
        labelSize={16}
        value={newPasswordInput}
        onChange={setNewPasswordInput}
        placeholder="New Password"
      />
      <InputBox
        label="Confirm New Password"
        labelSize={16}
        value={confirmPasswordInput}
        onChange={handleConfirmPasswordChange}
        placeholder="Retype New Password"
        isMatchingPassword={isMatchingPassword}
      />
      <Button
        text="SET PASSWORD"
        backgroundColor="bg-empathED-blue"
        onClick={setPassword}
      ></Button>
      <button
        className="text-empathED-blue text-xs font-bold ml-1"
        onClick={goLogin}
      >
        Back to log in
      </button>
    </Modal>
  );
}
