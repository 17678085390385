import React, { useEffect } from "react";
import { useRouteHelper } from "../utils/RouteHelper";
import { useFetchHelper } from "../utils/FetchHelper";

const MainPage = () => {
  const { goLogin, goExperience } = useRouteHelper();
  const { verifySession } = useFetchHelper();
  useEffect(() => {
    verifySession().then((isValid) => {
      if (isValid) {
        goExperience();
      } else {
        goLogin();
      }
    });
  }, []);
  return <div></div>;
};

export default MainPage;
