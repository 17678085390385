import { useState } from "react";
import InputBox from "./InputBox";
import Button from "./Button";
import Modal from "./Modal";
import { useRouteHelper } from "../utils/RouteHelper";

const ResetPasswordModal = () => {
  const { goLogin, goResetPasswordEmail } = useRouteHelper();
  const [emailInput, setEmailInput] = useState("");
  const resetPasswordEmail = () => {
    if (!emailInput) {
      alert("Please enter your email address.");
      return;
    }
    fetch(`${process.env.REACT_APP_API_URL}/users/reset-password`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include", // To include cookies such as the session ID
      body: JSON.stringify({
        email: emailInput,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else if (response.status === 400) {
          return response.json().then((errorResponse) => {
            throw new Error(errorResponse.error);
          });
        } else {
          throw new Error("An unexpected error occurred when sending reset password email.");
        }
      })
      .then((responseJSON) => {
        console.log("Success in sending reset password email:", responseJSON);
        goResetPasswordEmail(emailInput);
      })
      .catch((error) => {
        alert(error.message);
      });
  };
  return (
    <Modal title="Reset Password">
      <InputBox
        label="Enter your email and we'll send instructions on how to reset your password."
        labelSize={12.5}
        value={emailInput}
        onChange={setEmailInput}
        placeholder="Email Address"
      />
      <Button
        text="SEND"
        backgroundColor="bg-empathED-blue"
        onClick={resetPasswordEmail}
      />
      <button
        className="text-empathED-blue text-xs font-bold ml-1"
        onClick={goLogin}
      >
        Back to log in
      </button>
    </Modal>
  );
};

export default ResetPasswordModal;
