import { createSlice } from "@reduxjs/toolkit";
import { Quiz, QuizSliceState } from "../../utils/globals";

const initialState: QuizSliceState = {
  userEmail: "",
  quiz: {} as Quiz,
  answers: {},
  scorePercentage: 0,
  correctQuestions: 0,
  totalQuestions: 0,
  attempts: 0,
};

const quizSlice = createSlice({
  name: "quizSlice",
  initialState,
  reducers: {
    setUserEmail: (state, action) => {
      state.userEmail = action.payload;
    },
    setQuiz: (state, action) => {
      state.quiz = action.payload;
      const numberOfQuestions = state.quiz.Questions.length;
      const initAnswer: { [key: number]: string[] } = {};

      for (let i = 0; i < numberOfQuestions; i++) {
        initAnswer[i] = []; // Initialize an empty array for each question index
      }

      state.answers = initAnswer;
    },
    addAnswer: (state, action) => {
      const { questionIndex, answers } = action.payload;

      // Directly update the answers for the specific question index
      state.answers[questionIndex] = answers;
    },
    clearAnswers: (state) => {
      state.answers = {}; // Clear all answers
    },
    setScorePercentage: (state, action) => {
      state.scorePercentage = action.payload;
    },
    setCorrectQuestions: (state, action) => {
      state.correctQuestions = action.payload;
    },
    setTotalQuestions: (state, action) => {
      state.totalQuestions = action.payload;
    },
    setAttempts: (state, action) => {
      state.attempts = action.payload;
    },
  },
});

export const {
  setUserEmail,
  setQuiz,
  addAnswer,
  clearAnswers,
  setScorePercentage,
  setCorrectQuestions,
  setTotalQuestions,
  setAttempts,
} = quizSlice.actions;

export default quizSlice.reducer;
