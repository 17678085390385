import { combineSlices, configureStore } from "@reduxjs/toolkit";
import quizSliceReducer from "./pages/quiz_pages/quiz-slice";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { PersistPartial } from "redux-persist/es/persistReducer"; // Import PersistPartial type

// Persist configuration for Redux store
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["quizSliceReducer"], // Only persist quizSliceReducer
};

// `combineSlices` automatically combines the reducers using
// their `reducerPath`s, therefore we no longer need to call `combineReducers`.
const rootReducer = combineSlices({ quizSliceReducer });

// Wrap the rootReducer with persistReducer to enable persistence
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Infer the `RootState` type from the root reducer
export type RootState = ReturnType<typeof persistedReducer>;

// The store setup is wrapped in `makeStore` to allow reuse
// when setting up tests that need the same store config
export const makeStore = (
  preloadedState?: Required<Partial<RootState> & PersistPartial>,
) => {
  const store = configureStore({
    reducer: persistedReducer,
    preloadedState,
  });
  return store;
};

export const store = makeStore();

// Create the persistor for redux-persist
export const persistor = persistStore(store);

// Infer the type of `store`
export type AppStore = typeof store;
// Infer the `AppDispatch` type from the store itself
export type AppDispatch = AppStore["dispatch"];
