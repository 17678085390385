import { Quiz } from "../../utils/globals";
import dyslexiaQuiz from "../../pages/quiz_pages/quiz_content/DyslexiaQuiz.json";
import QuizPage from "./QuizPage";

const DyslexiaQuizModule = () => {
  const quiz: Quiz = dyslexiaQuiz as Quiz;
  return <QuizPage quiz={quiz} />;
};

export default DyslexiaQuizModule;
